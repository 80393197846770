<template>
  <FlexCenter class="flex-col">
    <ImageTitle :src="require('@/assets/photography.png')" />
    <DefaultText>O Olho Gordo possui diversas louças, cenários e outros elementos para composição de cena, tudo isso poderá ser utilizado junto de seu produto para realçar ainda mais sua beleza.</DefaultText>
    <DefaultText>As fotos podem ser feitas em nosso estúdio ou onde nosso cliente achar mais conveniente. </DefaultText>
    <div class="grid grid-cols-3 gap-0 mt-10">
      <img
        v-for="(item, index) in images"
        :key="index"
        v-lazy="item"
      >
    </div>
    <div class="content-center">
      <router-link to="/galeria">
        <img :src="imageShowMoreSrc" class="p-5 pt-10 sm:w-8/12 sm:block sm:ml-auto sm:mr-auto block ml-auto mr-auto w-56 -mb-14 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 lg:w-2/12"/>
      </router-link>
    </div>
  </FlexCenter>
</template>

<script lang="ts">
import FlexCenter from './FlexCenter.vue';
import DefaultText from './DefaultText.vue';
import ImageTitle from './ImageTitle.vue';

function importAll(r) {
  return r.keys().map(r);
}

const photograpy_files = importAll(require.context('../assets/photograpy/', false, /\.(png|jpe?g|svg)$/));

export default ({
  components: {
    FlexCenter,
    DefaultText,
    ImageTitle
  },
  data() {
    return {
      images: photograpy_files,
      imageTitleSrc: require('@/assets/photography.png'),
      imageShowMoreSrc: require('@/assets/show_more.png'),
    };
  },
});
</script>
